import { useQuery } from '@tanstack/react-query';
import { BundleRelease, useEngineeringBackend } from '../../../api';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export const useLatestProjectBundleConfigurationRelease = (projectId: number) => {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({
    projectId: String(projectId)
  });

  return useQuery<BundleRelease | null, [string, string]>(
    ['latestBundleRelease', projectId],
    async (): Promise<BundleRelease | null> => {
      const release = await backend.getLatestProjectBundleConfigurationRelease(projectId);
      return release.latest || null;
    },
    {
      enabled: projectId > -1 && permissions.engineeringSvc$getLatestProjectBundleConfigurationRelease
    }
  );
};
